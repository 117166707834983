dmx.Component('view', {

    initialData: {
        loading: false,
        params: null
    },

    attributes: {
        router: {
            type: String,
            default: 'server' // server or client (default to server for node templates)
        }
    },

    tag: 'div',

    events: {
        load: Event,
        error: Event,
        unauthorized: Event,
        forbidden: Event,
        notfound: Event
    },

    render: function(node) {
        this.xhr = new XMLHttpRequest();
        this.xhr.addEventListener('load', this.onload.bind(this));
        this.xhr.addEventListener('abort', this.onabort.bind(this));
        this.xhr.addEventListener('error', this.onerror.bind(this));
        this.xhr.addEventListener('timeout', this.ontimeout.bind(this));

        if (this.props.router == 'server') {
            this.url = location.pathname;
        }

        this.$parse();
    },

    update: function(props) {
        if (this.props.router == 'server') {
            var url = location.pathname;

            if (this.url == url && this.data.loading) {
                // Url is loading
                return;
            }

            if (this.url != url) {
                this.set('loading', true);

                this.url = url;

                url += (url.includes('?') ? '&' : '?') + 'fragment=true';

                this.xhr.abort();
                this.xhr.open('GET', url);
                this.xhr.setRequestHeader('accept', 'text/fragment+html');
                this.xhr.send();
            }
        } else {
            var path = dmx.routing.getUrlInfo().path;
            var routes = dmx.routing.getRoutes();
            var parent = this.parent;
            
            while (parent) {
                if (parent.routes) {
                    routes = parent.routes;
                    break;
                }

                parent = parent.parent;
            }

            var route = dmx.routing.match(path, routes, parent);

            if (route) {
                this.path = route.path;
                this.routes = route.routes;

                this.set('params', route.params);

                if (this.url != route.url) {
                    this.children.splice(0).forEach(function(child) {
                        child.$destroy();
                    });
                    
                    this.bindings = [];
                    this.$node.innerHTML = '';

                    this.set('loading', true);
    
                    this.url = route.url;
    
                    this.xhr.abort();
                    this.xhr.open('GET', route.url);
                    // lets add the fragment header just in case for node
                    this.xhr.setRequestHeader('accept', 'text/fragment+html');
                    this.xhr.send();
                }
            } else {
                console.warn('Route for ' + path + ' not found');
            }
        }
    },

    evalScripts: function(node) {
        var scripts = node.querySelectorAll('script[type="text/javascript"],script:not([type])');

        dmx.array(scripts).forEach(function(script) {
            var newScript = document.createElement('script');
            newScript.type = 'text/javascript';
            if (script.src) newScript.src = script.src;
            if (script.innerHTML) newScript.innerHTML = script.innerHTML;
            script.parentNode.replaceChild(newScript, script);
        });
    },

    onload: function(event) {
        this.set('loading', false);
        if (this.xhr.status == 200 || this.xhr.status == 0) {
            // correctly destroy old children first
            this.children.splice(0).forEach(function(child) {
                child.$destroy();
            });
            
            this.bindings = [];
            this.$node.innerHTML = this.xhr.responseText;
            
            this.$parse(this.$node);

            this.evalScripts(this.$node);

            if (window.grecaptcha) {
                dmx.array(this.$node.querySelectorAll('.g-recaptcha')).forEach(function(node) {
                    grecaptcha.render(node);
                });
            }

            this.dispatchEvent('load');
        } else {
            if (this.xhr.status == 222) {
                location.assign(this.xhr.responseText);
            } else if (this.xhr.status == 401) {
                this.dispatchEvent('unauthorized');
            } else if (this.xhr.status == 403) {
                this.dispatchEvent('forbidden');
            } else if (this.xhr.status == 404) {
                this.dispatchEvent('notfound');
            } else {
                this.dispatchEvent('error');
            }
        }
    },

    onabort: function(event) {
        this.set('loading', false);
    },

    onerror: function(event) {
        this.set('loading', false);
        this.dispatchEvent('error');
    },

    ontimeout: function(event) {
        this.set('loading', false);
        this.dispatchEvent('error');
    }

});